<template>
  <aside>
    <header>
      <h2>Sendoso Integration</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <div class="setting-row">
        <div class="title">
          <h4>Connect to Sendoso</h4>
          <p>Create or refresh your connection to Sendoso</p>
        </div>
        <div class="fields">
          <button @click.prevent="authSendoso" class="btn btn-xs btn-green">{{active ? 'Refresh connection' : 'Connect to Sendoso'}}</button>
        </div>
      </div>

      <div class="setting-row" v-if="active">
        <div class="title">
          <h4>Statistics</h4>
          <p>Latest details about your integration.</p>
        </div>
        <div class="fields stats">
          <ul>
            <li class="status">
              <strong>Status</strong>
              <span :class="[integration.status]"><span>{{status_label}}</span></span>
            </li>
          </ul>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Sync Logs</h4>
          <p>View synchronization activity for the last 72 hours.</p>
        </div>
        <div class="fields">
          <ul v-if="logs.length > 0">
            <li v-for="(log, index) in logs" :key="`log_${index}`">
              <a href="#" @click.prevent="openLog(log)">{{$formatDate(log.created_at, $DateTime.DATETIME_SHORT)}}</a>
            </li>
          </ul>
          <span v-else>No logs available.</span>
        </div>
      </div>
    </main>
  </aside>
</template>

<script>
import {required} from 'vuelidate/lib/validators'

export default {
  computed: {
    integration() {
      return this.$store.state.integrations.find(i => i.integration_type === 'SENDOSO') || {};
    },
    status_label() {
      switch(this.integration.status) {
        case "INACTIVE":
          return 'Inactive';
        case "ACTIVE":
          return 'Active';
      }
    },
    active() {
      return this.integration.status === 'ACTIVE' ? true : false;
    }
  },
  data() {
    return {
      logs: []
    }
  },
  validations: {
  },
  mounted() {
    this.populateLogs()
  },
  methods: {
    openLog(log) {
      this.$root.$emit(
        'openModal',
        'Integrations/LogViewer',
        log,
        () => {}
      )
    },
    async populateLogs() {
      if(!_.isEmpty(this.integration)) {
        const resp = await this.$api.Integrations.get_logs(this.integration.id)
        this.logs = resp;
      }
    },
    close() {
      this.$emit('done')
    },
    async authSendoso() {
      const {
        VUE_APP_SENDOSO_REDIRECT_URI,
        VUE_APP_SENDOSO_CLIENT_ID,
      } = process.env;

      const state = this.$rand();
      localStorage.setItem('hithrive_bamboohr_state', state);

      window.location = `https://app.sendoso.com/oauth/authorize?response_type=code&client_id=${encodeURIComponent(VUE_APP_SENDOSO_CLIENT_ID)}&redirect_uri=${encodeURIComponent(VUE_APP_SENDOSO_REDIRECT_URI)}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.stats {
  > ul {
    display: block;
    margin: 0;
    padding: 0;

    > li {
      display: flex;
      border-bottom: 1px solid $light;
      padding: 5px 0;

      > strong {
        flex: 0 0 35%;
        display: block;
      }

      > span {
        flex: 1 0 1;
        display: block;
      }

      &.status {
        > span {
          > span {
            border-radius: 3px;
            background: $light;
            padding: 3px 5px;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 1em;
          }
          &.ACTIVE {
            > span {
              background: $green;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>